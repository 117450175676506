<template>
  <div id="product">
    <div class="reserve__wrapper">
      <div class="form__wrap">
        <div class="title">
          <!-- 체험 이름 -->
          프렌치 랙 스테이크
        </div>
        <div class="content">
          <div class="date__line df">
            <div class="date__box">
              <p><i class="el-icon-date"></i></p>
              <p>8.12 토</p>
            </div>
            <div class="personnel__box">성인2명 / 아동1명</div>
          </div>
        </div>
      </div>
      <div class="form__wrap">
        <div class="title">예약자 정보</div>
        <div class="content">
          <div class="reservation-person__button">
            <el-button type="text" @click="reservationPersonVisible = true">
              <!-- 예약자 정보 없을 시 -->
              체크인 시 필요합니다.
              <!-- 예약자 정보 있을 시 -->
              <!-- 홍길동 -->
            </el-button>
            <el-dialog title="예약자 정보" :visible.sync="reservationPersonVisible" class="full">
              <el-form ref="form" :model="reservationPerson">
                <div class="option__wrap">
                  <div class="title__line">예약자 이름</div>
                  <div class="content__line">
                    <el-input v-model="reservationPerson.name" placeholder="예약자 이름을 입력하세요"></el-input>
                  </div>
                </div>
                <div class="option__wrap">
                  <div class="title__line">휴대폰 번호</div>
                  <div class="content__line">
                  <el-input v-model="reservationPerson.tel" placeholder="휴대폰번호를 입력하세요"></el-input></div>
                </div>
                <div class="footer-button__wrap">
                  <ul>
                    <li>
                      <el-button class="type-submit">적용하기</el-button>
                    </li>
                  </ul>
                </div>
              </el-form>
            </el-dialog>
          </div>
        </div>
      </div>
      <div class="form__wrap">
        <div class="title">포인트 사용</div>
        <div class="content">
          <ul class="sum__line">
            <li>
              <div class="th">보유 포인트</div>
              <div class="td">10,000 p</div>
            </li>
            <li>
              <div class="th">사용 포인트</div>
              <div class="td"><el-input></el-input></div>
            </li>
            <li>
              <div class="th"> </div>
              <div class="td use-point-list">- 5000 p</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="form__wrap">
        <div class="title">할인 및 결제 정보</div>
        <div class="content">
          <ul class="sum__line">
            <li>
              <div class="th">예약금액</div>
              <div class="td">555,000원</div>
            </li>
            <li>
              <div class="th">쿠폰할인</div>
              <div class="td">-15,000원</div>
            </li>
            <li>
              <div class="th">총 결제 금액</div>
              <div class="td">540,000원</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="form__wrap">
        <div class="title">결제수단</div>
        <div class="content">
          <el-radio-group v-model="paymentMethod">
            <el-radio-button label="신용/체크카드">
              <span class="side-icon dft"><img src="@/assets/img/mobile/pay-icon01.svg" alt=""></span>
              <span class="side-icon ov"><img src="@/assets/img/mobile/pay-icon01-ov.svg" alt=""></span>
              신용/체크카드
            </el-radio-button>
            <el-radio-button label="가상계좌">
              <span class="side-icon dft"><img src="@/assets/img/mobile/pay-icon02.svg" alt=""></span>
              <span class="side-icon ov"><img src="@/assets/img/mobile/pay-icon02-ov.svg" alt=""></span>
              가상계좌
            </el-radio-button>
            <el-radio-button label="제로페이">
              <img src="@/assets/img/mobile/pay-icon03.svg" alt="">
            </el-radio-button>
            <el-radio-button label="토스페이">
              <img src="@/assets/img/mobile/pay-icon04.svg" alt="">
            </el-radio-button>
            <el-radio-button label="네이버페이">
              <img src="@/assets/img/mobile/pay-icon05.svg" alt="">
            </el-radio-button>
            <el-radio-button label="카카오페이">
              <img src="@/assets/img/mobile/pay-icon06.svg" alt="">
            </el-radio-button>
          </el-radio-group>
          <el-checkbox v-model="paymentMethodRemember" class="bottom-checkbox">이 결제수단을 다음에도 사용</el-checkbox>
        </div>
      </div>
      <div class="form__wrap">
        <el-form ref="form" :model="reservationAgree">
          <ul class="checkbox-group">
            <li><el-checkbox v-model="reservationAgree.agreeAll" @change="toggleAllAgreements">전체 동의</el-checkbox></li>
            <li>
              <el-checkbox v-model="reservationAgree.agree01">이용 및 취소/환불규정 동의 (필수)</el-checkbox>
              <el-button size="mini" class="agreefull-btn" @click="opendialog = true">전문보기</el-button>
            </li>
            <el-dialog title="이용 및 취소/환불 규정" :visible.sync="opendialog" class="agree__dialog full">
              <el-form>
                <div class="agree__dialog__container">
                  <div class="text">
                    <div class="title">예약전</div>
                    <p>환불 정책에 관한 상세 정보는 숙소 페이지와 결제 전 예약 단계에서 확인하실 수 있습니다.</p>
                    <div class="title">예약 후</div>
                    <p>여행에서 환불 정책 및 옵션을 언제든 확인하실 수 있습니다. 여행 세부 정보 보기를 클릭하면 환불 정책을 확인할 수 있습니다. 환불 정책에 표시되는 시간과 날짜는 숙소가 위치한 지역의 현지 시간대를 기준으로 합니다. 환불이 가능한 취소 마감 시한은 숙소 정보에 표시된 시간(현지 시간)이 적용되며, 명시되지 않은 경우에는 오후 3시로 간주됩니다.
                    환불 금액을 알고 싶다면 예약 취소 절차를 시작하세요. 그러면 상세 내역이 표시됩니다. 숙박 기간, 취소 시기, 예약에 적용되는 환불 정책에 따라 체크인 후 예약을 취소하면 일부 환불이 가능할 수 있습니다. 다른 환불 정책에 대해 자세히 알아보기
                    예약 취소 시 환불에 대해 자세히 알아보세요. 환불 금액은 취소 시 실제로 지불한 금액보다 클 수 없습니다. 환불 금액에 대해 자세히 알아보기</p>
                    <div class="title">정상참작이 가능한 상황</div>
                    <p>긴급 상황이나 자연재해로 인해 예약에 지장이 있었다면, 정상참작이 가능한 상황으로 간주되어 환불이 가능할 수 있습니다. 코로나19 대유행으로 인해 예약을 취소해야 한다면, 어떤 예약 취소 옵션이 있는지 알아보세요.
                    </p>
                    <div class="title">호스트의 환불 정책</div>
                    <p>호스트이거나 숙소의 환불 정책에 대해 자세히 알고 싶으시면 숙소에 적용되는 환불 정책을 확인하세요.
                    </p>
                    <div class="title">숙박 중 발생한 문제</div>
                    <p>숙소 도착 후 문제가 발생했으며 호스트가 신속하게 해결할 수 없는 상황인 경우, 재예약 및 환불 정책에 따라 보호받으실 수 있습니다.</p>
                  </div>
                </div>
              </el-form>
            </el-dialog>
            <!-- <li><el-checkbox v-model="reservationAgree.agree02">개인정보 수집 및 이용 동의 (필수)<el-button size="mini" class="agreefull-btn"  @click="opendialogsecond = true">전문보기</el-button></el-checkbox></li>
            <li><el-checkbox v-model="reservationAgree.agree03">개인정보 제3자 제공 동의 (필수)<el-button size="mini" class="agreefull-btn agreefull-secound"  @click="opendialogthird = true">전문보기</el-button></el-checkbox></li>
            <el-dialog title="개인정보 제3자 제공 동의" :visible.sync="opendialogthird" class="agree__dialog">
              <el-form>
                <div class="agree__dialog__container">
                  <div class="text">
                    <div class="title">결제 조건을 확인하고 결제진행에 동의합니다.<br><br>
                      개인정보 제3자 제공에 동의합니다.<br><br>
                      남해원스톱관광플랫폼은 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.<br><br>
                      회원님의 개인정보는 아래와 같은 목적으로 제공됩니다.<br><br>
                      동의는 거부할 수 있으나 이 경우 결제 진행이 어려울 수 있습니다.<br><br>
                    </div>
                    <div class="title">1. 상품 및 서비스 판매자(가맹점 및 중계기관)</div>
                    <p>· 개인(신용)정보를 제공 받는자 : <br>
                      - 상품 및 서비스의 판매자(가맹점)<br>
                      - 결제 중계기관(금융결제원, 하나카드, 우리카드), 직매입 가맹점(한국철도공사, SRT)<br><br>
                      · 제공하는 개인(신용)정보 항목<br>
                      - 일반가맹점 : 결제수단, 결제금액 및 내용(할인, 포인트, 상품권, 머니금액 등 포함), 결제일시, 할부개월 수<br>
                      - 결제중계기관 : 결제수단, 결제금액 및 내용(할인, 포인트, 상품권, 머니금액 등 포함), 할부개월 수, 가상토큰번호(OTC), 유효기간<br><br>
                      · 개인(신용)정보 제공 목적<br>
                      - 일반 가맹점 : 판매자와 구매자의 거래 진행<br>
                      - 결제 중계 기관 : 가맹점 직접 승인/매입(원거래 승인 및 취소, 수수료 처리)을 위한 제공<br>
                      · 제공받는 자의 개인(신용)정보 보유 및 이용기간<br><br>
                      - 관련 법령에 따른 보관의무 기간 동안<br>
                      </p>
                  <div class="title">2. 결제수단으로 선택한 금융사</div>
                    <p>· 개인(신용)정보를 제공 받는자 : <br>
                      - 회원님이 자동결제를 선택한 신용카드사 (삼성, 신한, KB국민, 하나(구 외환), 비씨, 씨티, 현대, 롯데, NH농협, 한국카카오은행(주))<br><br>
                      · 제공하는 개인(신용)정보 항목<br>
                      - 카드등록 및 자동결제 : 카드번호, 유효기간, 생년월일, 카드비밀번호2자리, CVC<br>
                      - 카드사 상담처리 및 부정사용방지 :서비스 이용기록, 단말기정보(단말기종류, OS, 기기고유식별값)<br><br>
                      · 개인(신용)정보 제공 목적<br>
                      - 카드등록 및 자동결제<br>
                      - 카드 사용 시 발생한 상담 처리를 위한 양사간 사용내역 확인, 카드 부정사용방지<br>
                      · 제공받는 자의 개인(신용)정보 보유 및 이용기간<br><br>
                      - 남해관광원스톱플랫폼 탈퇴 시 까지 또는 관계 법령에 따른 보관의무 기간 동안<br>
                      </p>
                  </div>
                </div>
              </el-form>
            </el-dialog> -->
            <!-- <li><el-checkbox v-model="reservationAgree.agree04">만 14세 이상 확인 (필수)<el-button size="mini" class="agreefull-btn agreefull-third" @click="opendialogfourth = true">전문보기</el-button></el-checkbox></li> -->
          </ul>
        </el-form>
      </div>
    </div>
    <div class="footer__container">
      <el-button class="footer__line"><div class="pay">355,000원 결제하기</div></el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  components: {},
  data() {
    return {
      reservationPersonVisible: false,
      reservationPerson: {
        name: '',
        tel: '',
      },
      paymentMethod: '',
      paymentMethodRemember: '',
      reservationAgree: {
        agreeAll: false,
        agree01: false,
        agree02: false,
        agree03: false,
        agree04: false,
      },
      opendialog: false,
      opendialogsecond: false,
      opendialogthird: false,
      opendialogfourth: false,
    };
  },
  watch: {
    // 감시자를 이용하여 agreeAll 값이 변경되면 하위 체크박스들의 값을 모두 변경
    'reservationAgree.agreeAll': function (newVal) {
      this.reservationAgree.agree01 = newVal;
      this.reservationAgree.agree02 = newVal;
      this.reservationAgree.agree03 = newVal;
      this.reservationAgree.agree04 = newVal;
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleChange(value) {
      console.log(value);
    },
    toggleAllAgreements() {
      const allAgreed = this.reservationAgree.agreeAll;
      this.reservationAgree.agree01 = allAgreed;
      this.reservationAgree.agree02 = allAgreed;
      this.reservationAgree.agree03 = allAgreed;
      this.reservationAgree.agree04 = allAgreed;
    },
  },
};
</script>
